<template>
    <div>
        <c-tab
            v-show="popupParam.processType == 'P'"
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            align="left"
        >
            <template v-slot:default="tab">
                <component
                    v-if="tab.name == 'processDetail'"
                    ref="processDetail"
                    :is="tab.component"
                    :processCd.sync="popupParam.processCd"
                />
                <component
                    v-else
                    ref="processUnit"
                    :is="tab.component"
                    :processCd.sync="popupParam.processCd"
                />
            </template>
        </c-tab>
        <template>
            <component
                v-show="popupParam.processType == 'C'"
                ref="processUnitDetail"
                :is="component"
                :processCd="popupParam.processCd"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: "process-info-pop",
    props: {
        popupParam: {
            type: Object,
            default: () => ({
                processType: "P", // P: 상위공정, C: 하위공정
                processCd: "", // 공정코드
            }),
        },
    },
    data() {
        return {
            tab: "processDetail",
            tabItems: [
                {
                    name: "processDetail",
                    icon: "groups",
                    label: "공정상세",
                    component: () => import(`${"./processDetailPop.vue"}`),
                },
                {
                    name: "processUnit",
                    icon: "list",
                    label: "하위공정",
                    component: () => import(`${"./processUnitPop.vue"}`),
                },
            ],
            selectedProcessCd: "",
            selectedProcess: null,
            component: null,
        };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
        Object.assign(this.$data, this.$options.data());
    },
    mounted() {
        this.init();
    },
    beforeDestroy() {},
    watch: {},
    methods: {
        init() {
            console.log("this.popupParam.processCd", this.popupParam.processCd);
            if (this.popupParam.processType == "C") {
                this.component = () =>
                    import(`${"./processUnitDetailPop.vue"}`);
            }
        },
    },
};
</script>
